/* eslint-disable no-prototype-builtins */
import React, { Fragment, useEffect } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { MantineProvider } from '@mantine/core';
import Meta from './Meta';

import Footer from './Footer';
import Nav from './Nav';
// import Nav from './Nav';
// import Footer from './Footer';

import 'modern-normalize/modern-normalize.css';
import './globalStyles.css';

export default ({ children, title, meta }) => {
  return (
    <StaticQuery
      query={graphql`
        # Welcome to Altair GraphQL Client.
        # You can send your request using CmdOrCtrl + Enter.

        # Enter your graphQL query here.

        query settings {
          directus {
            Product_Category {
              Title
              Unique_Name
            }
            Global_Settings {
              id
              Fav_Icon {
                id
              }
              Default_Seo {
                id
                Share_Image {
                  id
                }
                Meta_Title
                Meta_Description
              }
              Google_Analytyics_Id
              Site_Name
              Body_Background_Colour
              Nav_Background_Colour
              Primary_Text_Colour
              Secondary_Text_Colour
              Footer_Background_Colour
              News_Item_Background_Colour
            }
          }
        }
      `}
      render={data => {
        const { Product_Category } = data.directus;
        const {
          Site_Name,
          Google_Analytyics_Id,
          Default_Seo,
          Nav_Background_Colour,
          Body_Background_Colour,
          Primary_Text_Colour,
          Secondary_Text_Colour,
          Footer_Background_Colour,
          News_Item_Background_Colour
        } = data.directus.Global_Settings || {};
        const seoData = meta?.Default_Seo || Default_Seo;

        useEffect(() => {
          const root = document.documentElement;

          root.style.setProperty('--body_background', Body_Background_Colour);
          root.style.setProperty('--nav_background', Nav_Background_Colour);
          root.style.setProperty('--primary', Primary_Text_Colour);
          root.style.setProperty('--secondary', Secondary_Text_Colour);
          root.style.setProperty(
            '--footer_background',
            Footer_Background_Colour
          );
          root.style.setProperty(
            '--news_item_background',
            News_Item_Background_Colour
          );
        }, [
          Nav_Background_Colour,
          Body_Background_Colour,
          Primary_Text_Colour,
          Secondary_Text_Colour,
          Footer_Background_Colour,
          News_Item_Background_Colour
        ]);
        const seoImageUrl = seoData?.Share_Image
          ? `https://sanders.clockworkbee.co.uk/assets/${seoData.Share_Image.id}`
          : null;

        return (
          <>
            <Helmet
              defaultTitle={Site_Name}
              titleTemplate={`%s | ${Site_Name}`}
            >
              {title}
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
              />
              <link
                href="https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap"
                rel="stylesheet"
              />

              {/* Add font link tags here */}
            </Helmet>
            <Meta
              googleTrackingId={Google_Analytyics_Id}
              absoluteImageUrl={seoImageUrl}
              description={seoData.Meta_Description}
              title={seoData.Meta_Title}
              siteTitle={Site_Name}
              siteDescription={seoData.Meta_Description}
            />
            <Nav subNav={Product_Category} />
            <MantineProvider theme={{ fontFamily: 'BioRhyme' }}>
              {children}
            </MantineProvider>
            <Footer />
          </>
        );
      }}
    />
  );
};
