import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import './Logo.css';

export default () => (
  <div className="Logo">
    <StaticImage
      src="../images/logo.png"
      width={300}
      placeholder="none"
      quality={95}
      formats={['auto', 'webp', 'avif']}
    />
  </div>
);
