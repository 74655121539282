/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Location } from '@reach/router';
import { Link, navigate } from 'gatsby';
import { Menu, createStyles } from '@mantine/core';
import { Menu as FeatherMenu, X } from 'react-feather';
import Logo from './Logo';

import './Nav.css';

const useStyles = createStyles(theme => ({
  body: {
    backgroundColor: 'var(--nav_background)',
    borderColor: 'black'
  },
  itemLabel: {
    fontFamily: 'BioRhyme'
  },
  itemHovered: {
    backgroundColor: 'var(--nav_background)',
    color: theme.white
  }
}));
const Navigation = props => {
  const [active, setActive] = useState(false);
  const [currentPath, setCurrentPath] = useState(false);

  useEffect(() => {
    setCurrentPath(props.location.pathname);
  }, []);

  const { classes } = useStyles();

  const handleMenuToggle = () => setActive(!active);

  // Only close nav if it is open
  const handleLinkClick = () => active && handleMenuToggle();

  // keyboard events
  const handleLinkKeyDown = ev => {
    if (ev.keyCode === 13) {
      active && handleMenuToggle();
    }
  };

  const NavLink = ({ to, className, children, ...props }) => (
    <Link
      to={to}
      className={`NavLink ${to === currentPath ? 'active' : ''} ${className}`}
      onClick={handleLinkClick}
      onKeyDown={handleLinkKeyDown}
      tabIndex={0}
      aria-label="Navigation"
      role="button"
      {...props}
    >
      {children}
    </Link>
  );
  const [activeSubNav, setActiveSubNav] = useState(false);

  const toggleSubNav = subNav => {
    setActiveSubNav(activeSubNav === subNav ? false : subNav);
  };

  const keyToggleSubNav = (e, subNav) => {
    // key o is for open so you can enter key to open
    if (e.keyCode === 79 || e.keyCode === 27) {
      toggleSubNav(subNav);
    }
  };
  const menuItems = props.subNav.map(i => (
    <Menu.Item
      onClick={() => {
        navigate(`/${i.Unique_Name}`);
      }}
    >
      {i.Title}
    </Menu.Item>
  ));
  return (
    <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
      <div className="Nav--Container container">
        <Link
          to="/"
          onClick={handleLinkClick}
          onKeyDown={handleLinkKeyDown}
          tabIndex={0}
          aria-label="Navigation"
          role="button"
        >
          <Logo />
        </Link>
        <div className="Nav--Links">
          <NavLink to="/">Home</NavLink>
          <Menu
            classNames={classes}
            control={
              <div
                className={`Nav--Group ${
                  activeSubNav === 'posts' ? 'active' : ''
                }`}
              >
                <span
                  className="NavLink Nav--GroupParent"
                  onClick={() => toggleSubNav('posts')}
                  onKeyDown={e => keyToggleSubNav(e, 'posts')}
                  tabIndex={0}
                  aria-label="Navigation"
                  role="button"
                >
                  Our Range
                </span>
              </div>
            }
          >
            {menuItems}
          </Menu>

          <NavLink to="/articles-page/">Blog</NavLink>
          <NavLink to="/contact-page/">Contact</NavLink>
        </div>
        <button
          className="Button-blank Nav--MenuButton"
          onClick={handleMenuToggle}
          tabIndex={0}
          aria-label="Navigation"
        >
          {active ? <X /> : <FeatherMenu />}
        </button>
      </div>
    </nav>
  );
};

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
);
