import React from 'react';
// import { RichText } from '@graphcms/rich-text-react-renderer';

import PropTypes from 'prop-types';

import './Content.css';

const Content = ({ source, className = '' }) => {
  // accepts either html or markdown

  return (
    <div className={`Content ${className}`}>
      <div dangerouslySetInnerHTML={{ __html: source }} />
    </div>
  );
};

Content.propTypes = {
  source: PropTypes.string,
  className: PropTypes.string
};

Content.defaultProps = {
  source: '',
  className: ''
};

export default Content;
